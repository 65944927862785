<script setup>
const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  isEmptyValue: {
    type: Boolean,
    default: false,
  },
  appearance: {
    type: String,
    default: 'warning',
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
  denyButtonText: {
    type: String,
  },
  confirmButtonText: {
    type: String,
  },
  denyButtonAppearance: {
    type: String,
    default: 'secondary',
  },
  confirmButtonAppearance: {
    type: String,
    default: 'primary',
  },
})

const emit = defineEmits(['confirm', 'deny'])
const disabledButton = ref(false)
const disabledButtonConfirm = ref(false)

watch(
  () => props.showModal,
  () => {
    disabledButton.value = false
  },
)

watch(
  () => props.isEmptyValue,
  (val) => {
    disabledButtonConfirm.value = val
  },
)

function handleClick(action) {
  disabledButton.value = true
  emit(action)
}
</script>

<template>
  <KModal :show-modal="showModal" style="width: 418px; padding: 4px 8px; overflow: visible;">
    <template #title>
      <h1 class="text-lg font-semibold tracking-[0.02px]">
        {{ title }}
      </h1>
    </template>
    <template #message>
      <p class="text-xs mb-4">
        {{ message }}
      </p>
      <slot />
      <slot name="modalForm" />
    </template>
    <template #buttons>
      <div class="flex gap-2 w-full">
        <KButton
          v-if="denyButtonText"
          :class="{ 'w-1/2': confirmButtonText, 'w-full': !confirmButtonText, 'danger bg-danger': denyButtonAppearance === 'danger' }" :appearance="denyButtonAppearance" :disabled="disabledButton" @click="handleClick('deny')"
        >
          {{ denyButtonText }}
        </KButton>
        <slot name="btnConfirm">
          <KButton
            v-if="confirmButtonText"
            :class="{ 'w-1/2': denyButtonText, 'w-full': !denyButtonText, 'danger bg-danger': confirmButtonAppearance === 'danger' }" :appearance="confirmButtonAppearance" :disabled="disabledButton || disabledButtonConfirm" @click="handleClick('confirm')"
          >
            {{ confirmButtonText }}
          </KButton>
        </slot>
      </div>
    </template>
  </KModal>
</template>

<style scoped>
.danger{
  @apply text-white
}

.bg-danger{
  @apply bg-[#B22A09]
}

.bg-danger:hover{
  @apply bg-[#D81600]
}
</style>

<style>
#alasan-pembatalan-menu {
  position: fixed;
  max-width: 370px;
}
</style>
